import React from 'react'
import Layout from './../Layout/Layout';
import Head from '../Components/Head';
import aboutus from '../images/aboutus.jpg'

function AboutUs() {
	return (
		<Layout>
			<div className="min-height-screen container mx-auto px-2 my-6">
				<Head title="About Us" />
				<div className="xl:py-20 py-10 px-4">
					<div className="grid grid-flow-row xl:grid-cols-2 gap-4 xl:gap-16 items-center">
						<div>
							<h3 className="text-xl lg:text-3xl mb-4 font-semibold">
								Welcome To MovieXone. Your best is here 
							</h3>
							<div className="mt-3 text-sm leading-8 text-text">
							<p>
								Video provides a powerful way to help you prove your point. When you click Online Video, you can paste in the embed code for the video you want to add. You can also type a keyword to search online for the video that best fits your document.
								To make your document look professionally produced, Word provides header, footer, cover page, and text box designs that complement each other. For example, you can add a matching cover page, header, and sidebar. Click Insert and then choose the elements you want from the different galleries.
							</p>
							<p>
								Video provides a powerful way to help you prove your point. When you click Online Video, you can paste in the embed code for the video you want to add. You can also type a keyword to search online for the video that best fits your document.
								To make your document look professionally produced, Word provides header, footer, cover page, and text box designs that complement each other. For example, you can add a matching cover page, header, and sidebar. Click Insert and then choose the elements you want from the different galleries.
							</p>
							</div>
							<div className="grid md:grid-cols-2 gap-6 mt-8">
								<div className="p-8 bg-dry rounded-lg">
								<span className="text-3xl block font-extrabold mt-4">
									10K
								</span>
								<h4 className='text-lg font-semibold my-2'>
									Listed Movies
								</h4>
								<p className="mb-0 text-text leading-7 text-sm">
									To make your document look professionally produced,
								</p>
								</div>
								<div className="p-8 bg-dry rounded-lg">
								<span className="text-3xl block font-extrabold mt-4">
									8K
								</span>
								<h4 className='text-lg font-semibold my-2'>
									Lovely Users
								</h4>
								<p className="mb-0 text-text leading-7 text-sm">
									Completely Free Without Registration
								</p>
								</div>
							</div>
						</div>
						<div className="mt-10 lg:mt-0">
							<img src={aboutus} alt="aboutus" className="w-full xl:block hidden h-header rounded-lg object-cover" />
						</div>

					</div>
				</div>
			</div>
		</Layout>
	)
}

export default AboutUs;