import React from 'react';
import { Link } from 'react-router-dom';

const ArticleCardMiddle = ({ article, innerRef }) => {
    if (!article || !article.slug || !article.image || !article.brief || !article.articleId) {
        return null;
    }

    return (
        <Link to={`/${encodeURIComponent(article.slug)}`} state={{ article }}>
            <div ref={innerRef} className="p-4 flex flex-col items-start group">
                {/* Title at the top */}
                <h2 className="text-lg font-semibold text-gray-800 mb-2 group-hover:text-blue-500 transition-colors duration-300">
                    {article.title}
                </h2>
                <div className="flex">
                    {/* Image in the middle */}
                    <img
                        src={article.image}
                        alt={article.title}
                        className="w-1/3 object-cover mr-4"
                        loading="lazy"
                    />

                    {/* Brief text below image */}
                    <p className="text-sm text-gray-600">
                        {article.brief}...
                    </p>
                </div>
            </div>
        </Link>
    );
};

export default ArticleCardMiddle;
