import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Titles from './../Titles';
import { BsBookmarkStarFill, BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { FaHeart } from 'react-icons/fa';
import Rating from '../Stars.js';
import { saveArticles, getArticles, clearArticles } from '../../indexedDBHelper'; // Import IndexedDB helper functions

function TopRated() {
  const [nextEl, setNextEl] = useState(null);
  const [prevEl, setPrevtEl] = useState(null);
  const [articles, setArticles] = useState([]);
  const classNames = "hover:bg-subMain transitions text-sm rounded-full w-12 h-12 flex justify-center items-center bg-dry text-white";

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Clear IndexedDB to ensure fresh session data (optional, can be removed)
        await clearArticles('topRatedStore'); // Use an appropriate store name

        // Check if articles are already stored in IndexedDB
        const storedArticles = await getArticles('topRatedStore'); // Use an appropriate store name
        if (storedArticles.length) {
          setArticles(storedArticles);
        } else {
          // Fetch articles from the API if not found in IndexedDB
          const response = await fetch('/cache/articles_most_cache.json');
          if (!response.ok) {
            throw new Error('Failed to fetch articles');
          }
          const data = await response.json();
          setArticles(data);
          await saveArticles('topRatedStore', data); // Save to IndexedDB
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  const maxSlidesToRender = 10; // Limit the number of slides rendered
  const limitedArticles = articles.slice(0, maxSlidesToRender);

  return (
    <div className="my-16">
      <div className="flex justify-between items-center">
        <Titles title='Hot Topics' Icon={BsBookmarkStarFill} />
        <Link to="/hot"
          className="text-white text-sm font-medium bg-subMain px-4 py-2 rounded hover:bg-dryGray transition duration-300"
          style={{ whiteSpace: 'nowrap' }}
        >
          See All
        </Link>
      </div>
      <div className="mt-10">
        <Swiper
          navigation={{ nextEl, prevEl }}
          spaceBetween={20}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          speed={1000}
          loop={true}
          modules={[Navigation, Autoplay]}
          lazy={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {limitedArticles.map((article, index) => (
            <SwiperSlide key={index}>
              <div className='p-4 h-rate hovered border-border bg-dry rounded-lg overflow-hidden relative'>
                <Link className="font-semibold text-xl line-clamp-2"
                  to={article.link}
                >
                  <img
                    src={article.image === "No image" ? "/default_image_path/default.jpg" : article.image}
                    alt={article.title}
                    className="w-full h-full object-cover absolute inset-0"
                    loading="lazy"
                  />
                  <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-70 text-white mb-3">
                    {article.title}
                  </div>
                </Link>
                <div className="px-4 hoveres gap-6 text-center absolute bg-black bg-opacity-30 top-0 left-0 right-0 bottom-0">
                  <button className="w-12 h-12 flex-colo transitions hover:bg-subMain rounded-full bg-white bg-opacity-30 text-white">
                    <FaHeart />
                  </button>
                  <div className="flex gap-2 text-star">
                    <Rating value={article.rating || 0} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='w-full flex justify-center items-center gap-2 pt-12'>
          <button className={classNames} ref={(node) => setPrevtEl(node)}>
            <BsCaretLeftFill />
          </button>
          <button className={classNames} ref={(node) => setNextEl(node)}>
            <BsCaretRightFill />
          </button>
        </div>
      </div>
    </div>
  );
}

export default TopRated;
