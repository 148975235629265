import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { FaSearch, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { CgUser } from 'react-icons/cg';
import "../../index.css";

function NavBar() {
    const hover = "hover:text-subMain text-white sm:text-white/70 relative group";
    const activeClass = "text-subMain relative after:content-[''] after:absolute after:left-0 after:top-8 after:h-[2px] after:w-full after:bg-subMain after:transition-width after:duration-600 after:ease-in-out";
    const Hover = ({ isActive }) => (isActive ? activeClass : hover);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isScrolledLeft, setIsScrolledLeft] = useState(false);
    const [isScrolledRight, setIsScrolledRight] = useState(false);
    const scrollContainerRef = useRef(null);
    const linkRefs = useRef([]);
    const location = useLocation();

    let hideTimeout;

    const showDropdown = () => {
        clearTimeout(hideTimeout);
        setDropdownVisible(true);
    };

    const hideDropdown = () => {
        hideTimeout = setTimeout(() => {
            setDropdownVisible(false);
        }, 200);
    };

    const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        setIsScrolledLeft(scrollLeft > 0);
        setIsScrolledRight(scrollLeft + clientWidth < scrollWidth);
    };

    const scrollLeft = () => {
        scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollRight = () => {
        scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };

    const scrollToCenter = (index) => {
        const item = linkRefs.current[index];
        if (item) {
            const { offsetLeft, offsetWidth } = item;
            const { clientWidth, scrollLeft } = scrollContainerRef.current;
            const targetScrollPos = offsetLeft - (clientWidth / 2) + (offsetWidth / 2);
            scrollContainerRef.current.scrollTo({ left: targetScrollPos, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        handleScroll();
        return () => clearTimeout(hideTimeout);
    }, []);

    useEffect(() => {
        const activeIndex = linkRefs.current.findIndex(ref => ref?.classList.contains(activeClass));
        if (activeIndex !== -1) {
            scrollToCenter(activeIndex);
        }
    }, [location]);

    return (
        <>
            <div className="bg-bgDark shadow-md sticky top-0 bottom-0 z-20 mb-0">
                <div className="container mx-auto py-4 lg:grid grid-cols-7 gap-5 items-center mb-0">
                    <div className="col-span-1 lg:block hidden">
                        <Link to="/">
                            <div className="md:px-1 container flex items-center logo">
                                <p className="logo text-subMain">Read</p>
                                <p className="logo">Nite</p>
                            </div>
                        </Link>
                    </div>
                    <div className="hidden lg:block col-span-2">
                        <form className="w-full text-sm bg-dryGray rounded flex items-center gap-0">
                            <button type="submit" className="bg-subMain w-12 h-12 flex items-center justify-center rounded text-white">
                                <FaSearch />
                            </button>
                            <input
                                type="text"
                                placeholder="Search news, blogs, articles, and stories..."
                                className="font-medium placeholder:text-border text-sm flex-1 h-10 bg-transparent border-none px-2 text-black box-border"
                            />
                        </form>
                    </div>

                    {/* menus */}
                    <div className="col-span-4 sm:col-span-4 xl:gap-7 2xl:gap-15 lg:flex xl:justify-end items-center relative">
                        <button
                            className={`absolute left-0 top-0 bottom-0 w-8 z-10 ${isScrolledLeft ? '' : 'hidden'}`}
                            onClick={scrollLeft}
                        >
                            <FaChevronLeft className="w-5 h-5 text-white/50" />
                        </button>
                        <button
                            className={`absolute right-0 top-0 bottom-0 w-8 z-10 ${isScrolledRight ? '' : 'hidden'}`}
                            onClick={scrollRight}
                        >
                            <FaChevronRight className="w-5 h-5 text-white/50" />
                        </button>
                        <div
                            className="no-scrollbar flex overflow-x-auto space-x-5 relative sm:overflow-x-visible lg:overflow-x-visible"
                            onScroll={handleScroll}
                            ref={scrollContainerRef}
                        >
                            {['/', '/hot', '/technology', '/finance', '/food', '/relationships'].map((path, index) => (
                                <NavLink
                                    key={path}
                                    to={path}
                                    className={Hover}
                                    ref={el => linkRefs.current[index] = el}
                                >
                                    {path === '/' ? 'Stories' : path.substring(1).charAt(0).toUpperCase() + path.substring(2)}
                                </NavLink>
                            ))}
                            <NavLink to="/" className={Hover} ref={el => linkRefs.current[7] = el}>
                                <CgUser className="w-8 h-8 hidden" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavBar;
