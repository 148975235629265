import React, { useState } from 'react';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import './styles.css';

function Comments({ articleId }) {
  const [refreshFlag, setRefreshFlag] = useState(false);

  const handleCommentSubmit = () => {
    setRefreshFlag(!refreshFlag);
  };

  return (
    <div className="echoes">
      <CommentList articleId={articleId} key={refreshFlag} />
      <CommentForm articleId={articleId} onCommentSubmit={handleCommentSubmit} />
    </div>
  );
}

export default Comments;
