import React from 'react'
import { FiUser } from 'react-icons/fi'
import mobile from '../../images/mobile.png'

function Promos() {
	return(
		<div className="my-20 py-10 md:px-20 px-8 bg-bgDark">
			<div className="lg:grid lg:grid-cols-2 lg:gap-10 items-center">
				<div className="flex lg:gap-10 gap-6 flex-col">
					<h1 className="xl:text-3xl text-xl capitalize font-sans font-medium leading-loose">
						Stay Updated with the Latest News and Stories <br /> Enjoy on Your Mobile
					</h1>
					<p className="text-text text-sm xl:text-base leading-6 xl:leading-8">
						Get the latest news and stories from your favorite sources in one convenient location. Our website allows you to download and watch offline, ensuring you stay informed even when you're on the go. With a wide range of sources and customizable options, you can tailor your feed to fit your interests and preferences. 
					</p>
					<div className="flex gap-4 md:text-lg text-sm">
						<div className="flex-colo bg-dry text-subMain px-6 py-3 rounded-md font-bold">
							HD 4K
						</div>
						<div className="flex-rows gap bg-dry text-subMain px-6 py-3 rounded font-bold">
							<FiUser /> 2K
						</div>
					</div>
				</div>
				<div className="">
					<img src={mobile} alt="Mobile app" className="w-full object-contain" />
				</div>
			</div>
		</div>
	);
}
export default Promos;