import React from 'react';
import Layout from "../Layout/Layout";
import { useParams } from "react-router-dom";
import { Movies } from "../Data/MovieData";
import MovieInfo from "../Layout/Layout";
import { BsCollectionFill } from 'react-icons/bs'
import Titles from '../Components/Titles';
import Movie from '../Components/Movie';

function Singlemovie () {
	const {id} = useParams()
	const movie = Movies.find((movie) => movie.name === id)
	const RelatedMovies = Movies.filter(
		(m) => m.category === movie.category
	);

	return (
		<Layout>
		<div className="">
			<MovieInfo movie={movie} />
			<div className="container mx-auto min-h-screen px-2 my-6">
				{/* rate */}
				{/* related */}
				<div className="my-16">
					<Titles titles="Related Movies" Icon={BsCollectionFill} />
					<div className="grid sm:mt-10 mt-6 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-">
						{RelatedMovies.map((movie,index) => (
						<Movie key={index} movie={movie} />
					))}
			</div>
				</div>
			</div>
		</div>
		</Layout>
	);
}

export default Singlemovie;
 