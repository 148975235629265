import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const ArticleCard = ({ article, innerRef }) => {
    const imgRef = useRef(null);

    // IntersectionObserver to lazy load the image based on its proximity to the viewport
    useEffect(() => {
        if (!article || !article.image) return; // Handle case where article or image is missing

        const img = imgRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const lazyImage = entry.target;
                        lazyImage.src = article.image; // Load the image once it is in view
                        observer.unobserve(lazyImage); // Stop observing once the image is loaded
                    }
                });
            },
            { rootMargin: '200px' } // Start loading images 200px before they enter the viewport
        );

        if (img) {
            observer.observe(img);
        }

        return () => {
            if (img) {
                observer.unobserve(img);
            }
        };
    }, [article]); // The effect depends on the `article` object

    // Ensure that essential fields, including category and articleId, are present
    if (!article || !article.slug || !article.image || !article.link || !article.category || !article.articleId) {
        return null;  // If essential data is missing, don't render the card
    }

    return (
        <Link
            to={`/${encodeURIComponent(article.slug)}`}  // Use slug as URL
            state={{ article }}  // Pass the full article object including category and articleId
        >
            <div
                ref={innerRef}
                className="bg-white rounded-lg p-2 shadow-lg flex group"
                style={{ height: '250px', position: 'relative' }}
            >
                <div className="relative" style={{ width: '50%' }}>
                    {/* Use a placeholder and lazy load the image */}
                    <img
                        ref={imgRef}
                        src=""
                        alt={article.title}
                        className="w-full h-full object-cover rounded"
                        loading="lazy"  // Add browser-level lazy loading
                    />
                </div>
                <div className="ml-4 flex flex-col justify-between relative" style={{ width: '50%' }}>
                    <div style={{ height: 'calc(100% - 52px)', overflow: 'hidden', position: 'relative' }}>
                        <h2 className="text-sm sm:text-lg font-bold text-gray-800 mb-1 group-hover:text-blue-500 transition-colors duration-300">
                            {article.title}
                        </h2>
                        <p className="text-sm sm:text-lg xl:text-sm text-gray-900 mb-2" style={{ paddingBottom: '30px' }}>
                            {article.brief}...
                        </p>
                        <div style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '0',
                            width: '100%',
                            height: '50px',
                            background: 'linear-gradient(to top, white, rgba(255, 255, 255, 0))'
                        }}></div>
                    </div>
                    <button
                        className="bg-black/10 hover:bg-blue-700 active:bg-blue-900 text-white font-bold py-2 px-4 rounded absolute bottom-0"
                        style={{ width: '150px' }}
                    >
                        Read More
                    </button>
                </div>
            </div>
        </Link>
    );
};

export default ArticleCard;
