import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import FlexMovieItems from '../FlexMovieItems';
import { FaHeart } from 'react-icons/fa';
import { saveArticles, getArticles, clearArticles } from '../../indexedDBHelper'; // Import IndexedDB helper functions

function Banner() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Clear IndexedDB to ensure fresh session data (optional, can be removed)
        await clearArticles('bannerArticlesStore'); // Use an appropriate store name

        // Check if articles are already stored in IndexedDB
        const storedArticles = await getArticles('bannerArticlesStore'); // Use an appropriate store name
        if (storedArticles.length) {
          setArticles(storedArticles);
        } else {
          // Fetch articles from the API if not found in IndexedDB
          const response = await fetch('/cache/articles_banner_cache.json');
          if (!response.ok) {
            throw new Error('Failed to fetch articles');
          }
          const data = await response.json();
          setArticles(data);
          await saveArticles('bannerArticlesStore', data); // Save to IndexedDB
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="relative w-full">
      <Swiper
        direction="vertical"
        slidesPerView={1}
        loop={true}
        speed={1000}
        modules={[Autoplay]}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        className="w-full xl:h-96 bg-bgDark lg:h-64 h-48"
      >
        {articles.map((article, index) => (
          <SwiperSlide key={index} className="relative rounded overflow-hidden">
            <img
              src={article.image === "No image" ? "/default_image_path/default.jpg" : article.image}
              alt={article.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute linear-bg xl:pl-52 sm:pl-32 pl-8 top-0 bottom-0 right-0 left-0 flex flex-col justify-center lg:gap-8 md:gap-5 gap-4">
              <h1 className="xl:text-4xl truncate capitalize font-sans sm:text-2xl text-xl font-bold">
                {article.title}
              </h1>
              <p className="text-base text-gray mb-2">
                {article.brief.slice(0, 450)}...
              </p>
              <div className="flex gap-5 items-center text-dryGray">
                <FlexMovieItems article={article} />
              </div>
              <div className="flex gap-5 items-center">
                <Link to={article.link} className="bg-subMain hover:text-main transition text-white px-8 py-3 rounded font-medium sm:text-sm text-xs">
                  Read More
                </Link>
                <button className="bg-white hover:text-subMain transition text-white px-4 py-3 rounded text-sm bg-opacity-30">
                  <FaHeart />
                </button>
                <Link to="/movies">
                  Movie
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Banner;
