import React, { useEffect, useState, useRef, useCallback } from 'react';
import Layout from './Layout/Layout';
import { saveArticles, getArticles } from './indexedDBHelper';
import ArticleCard from './ArticleCard';
import { useNavigate } from 'react-router-dom';
import ForYouFood from './foryou/ForYouFood.js'
import HeroFood from './heroBanners/HeroFood.js'

const Food = () => {
    const [articles, setArticles] = useState([]);
    const [visibleArticles, setVisibleArticles] = useState(10);
    const observer = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const storedArticles = await getArticles('foodArticlesStore');
                if (storedArticles.length > 0) {
                    setArticles(storedArticles);
                } else {
                    const response = await fetch('/cache/articles_food_cache.json', {
                        mode: 'cors',
                    });
                    if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);

                    const data = await response.json();
                    setArticles(data);
                    await saveArticles('foodArticlesStore', data);
                }
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };
        fetchArticles();
    }, []);

    const loadMoreArticles = () => {
        setVisibleArticles((prev) => prev + 10);
    };
 useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []); 
 
    const lastArticleElementRef = useCallback((node) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreArticles();
            }
        });
        if (node) observer.current.observe(node);
    }, []);
const handleArticleClick = async (slug) => {
    try {
        // Send click count update directly to PHP file
        await fetch('http://localhost/news/src/extras/update_click_count.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ slug }), // Send article slug to PHP
        });
    } catch (error) {
        console.error('Error updating click count:', error);
    }
};

    return (
        <Layout>
            <HeroFood />
            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
                <ForYouFood />
                <h1 className="text-2xl text-subMain text-left mb-8">Food and Cooking</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {articles.slice(0, visibleArticles).map((article, index) => (
                        <div 
                    key={article.slug} 
                    onClick={() => {
                        navigate(`/${article.slug}`, { state: { article } });
                        handleArticleClick(article.slug);
                    }}
                    >
                            <ArticleCard
                                article={article}
                                innerRef={index === visibleArticles - 1 ? lastArticleElementRef : null}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};
export default Food;
