import React, { useState, useEffect } from 'react';
import Titles from '../Titles';
import { BsCollectionFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { saveArticles, getArticles, clearArticles } from '../../indexedDBHelper'; // Import IndexedDB helper functions

function PopularMovies() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Clear IndexedDB to ensure fresh session data (optional, can be removed)
        await clearArticles('popularMoviesStore'); // Use an appropriate store name

        // Check if articles are already stored in IndexedDB
        const storedArticles = await getArticles('popularMoviesStore'); // Use an appropriate store name
        if (storedArticles.length) {
          setArticles(storedArticles);
        } else {
          // Fetch articles from the API if not found in IndexedDB
          const response = await fetch('/cache/articles_popular_cache.json');
          if (!response.ok) {
            throw new Error('Failed to fetch articles');
          }
          const data = await response.json();
          setArticles(data);
          await saveArticles('popularMoviesStore', data); // Save to IndexedDB
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="my-16">
      <div className="flex justify-between items-center">
        <Titles title="Trending Now" Icon={BsCollectionFill} />
        <Link to="/trending"
          className="text-white text-sm font-medium bg-subMain px-4 py-2 rounded hover:bg-dryGray transition duration-300"
          style={{ whiteSpace: 'nowrap' }}
        >
          See All
        </Link>
      </div>
      <div className="grid sm:mt-8 mt-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
        {articles.slice(0, 8).map((article, index) => (
          <div key={index} className="relative bg-white shadow-md rounded-lg overflow-hidden">
            <Link className="font-semibold text-xl line-clamp-2"
              to={article.link}
            >
              <img
                src={article.image === "No image" ? "/default_image_path/default.jpg" : article.image}
                alt={article.title}
                className="w-full h-52 object-cover"
              />
              <div className="absolute bottom-0 w-full h-1/4 bg-black bg-opacity-70 text-white p-2">
                <h2 className="text-sm font-bold">{article.title}</h2>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PopularMovies;
