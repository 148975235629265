import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Comments from './Comments';
import RelatedsTechnology from './RelatedsTechnology';
import RelatedsFinance from './RelatedsFinance';
import RelatedsGaming from './RelatedsGaming';
import RelatedsFood from './RelatedsFood';
import RelatedsNews from './RelatedsNews';
import RelatedsPopular from './RelatedsPopular';
import RelatedsMost from './RelatedsMost';
import RelatedsBanner from './RelatedsBanner';
import RelatedsRelationships from './RelatedsRelationships';
import './styles.css';

// Mapping of categories to related components
const relatedComponentsMap = {
    technology: RelatedsTechnology,
    finance: RelatedsFinance,
    gaming: RelatedsGaming,
    food: RelatedsFood,
    news: RelatedsNews,
    popular: RelatedsPopular,
    most: RelatedsMost,
    banner: RelatedsBanner,
    relationships: RelatedsRelationships,
};

// List of cache URLs for different article sources
const articleSources = [
    '/cache/articles_popular_cache.json',
    '/cache/articles_banner_cache.json',
    '/cache/articles_most_cache.json',
    '/cache/articles_technology_cache.json',
    '/cache/articles_food_cache.json',
    '/cache/articles_allBanner_cache.json',
    '/cache/articles_allPopular_cache.json',
    '/cache/articles_allMost_cache.json',
    '/cache/articles_relationships_cache.json',
    '/cache/articles_finance_cache.json',
    '/cache/articles_gaming_cache.json',
    '/cache/articles_health_cache.json',
    '/cache/articles_news_cache.json',
];

const ArticleLoader = () => {
    const { slug } = useParams(); // Get the slug from the URL
    const location = useLocation();
    const [article, setArticle] = useState(null);  // Initialize article as null
    const [relatedComponent, setRelatedComponent] = useState(null);
    const [categoryMessage, setCategoryMessage] = useState('');
    const [loading, setLoading] = useState(true); // Start loading by default
    const [error, setError] = useState(null);

    // Fetch article whenever slug changes
    useEffect(() => {
        const fetchArticleBySlug = async () => {
            setLoading(true); // Set loading to true on every fetch attempt
            let foundArticle = null;

            // Loop through all article sources until the article is found
            for (let source of articleSources) {
                try {
                    const response = await fetch(source); // Fetch data from the source
                    const data = await response.json();   // Parse the JSON
                    foundArticle = data.find((a) => a.slug === slug);  // Find the article by slug

                    if (foundArticle) {
                        setArticle(foundArticle);
                        setLoading(false);
                        break;  // Stop searching if the article is found
                    }
                } catch (err) {
                    console.error(`Error fetching from source ${source}:`, err);
                }
            }

            if (!foundArticle) {
                setError('Article not found');
                setLoading(false);
            }
        };

        fetchArticleBySlug(); // Fetch the article when slug changes
    }, [slug]);

    // Determine related component when article is fetched
    useEffect(() => {
        if (article) {
            const { category, articleId } = article;
            if (category && articleId) {
                const Component = relatedComponentsMap[category];
                if (Component) {
                    setRelatedComponent(() => Component);
                    setCategoryMessage(`Loading related content for category: ${category}`);
                } else {
                    console.error(`No related component found for category: ${category}`);
                    setCategoryMessage(`No related component found for category: ${category}`);
                    setRelatedComponent(null);
                }
            } else {
                setCategoryMessage('No category or article ID detected for this article.');
                setRelatedComponent(null);
            }
        }
    }, [article]);

    // Handle loading, error, or no article states
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!article) return <p>No article provided.</p>;

    const { link, title, articleId, category } = article;

    return (
        <div className="scroll-container">
            {link ? (
                <iframe
                    id="article-iframe"
                    src={link}
                    className="iframe-content"
                    style={{ border: 'none', width: '100%', height: '500px' }}
                    title={title}
                />
            ) : (
                <p>No article link provided.</p>
            )}

            <p className="message">{categoryMessage}</p>
            {relatedComponent ? (
                React.createElement(relatedComponent, { category: category || 'default' })
            ) : null}

            <Comments articleId={articleId} />
        </div>
    );
};

export default ArticleLoader;
