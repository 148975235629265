import React, { useEffect, useState } from 'react';

const Headlines = () => {
    const [headlines, setHeadlines] = useState([]);

    useEffect(() => {
        // Function to fetch titles from the cache
        const fetchHeadlines = async () => {
            try {
                const response = await fetch('/cache/articles_finance_cache.json', {
                    mode: 'cors',
                });
                if (!response.ok) throw new Error('Failed to fetch articles');

                const data = await response.json();
                const titles = data.map((article) => article.title); // Extract titles
                setHeadlines(titles);
            } catch (error) {
                console.error('Error fetching headlines:', error);
            }
        };

        fetchHeadlines();
    }, []);

    if (!headlines || headlines.length === 0) return null;

    return (
        <div className="container mx-auto py-10">
            <h1 className="text-2xl font-bold text-subMain mb-6">Headlines</h1>
            <div className="space-y-4">
                {headlines.map((title, index) => (
                    <div key={index}>
                        <h2 className="text-md text-gray-900 font-semibold">{title}</h2>
                        {index < headlines.length - 1 && (
                            <hr className="border-gray-300 my-4" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Headlines;
