export const Movies = [
  {
    name: 'Army Of The Dead',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '1a.jpg',
    image: '1.jpg',
    category: 'Western',
    language: 'English',
    year: '2022',
    time: '3hr',
    video: '',
    rate: 3.4,
    reviews: 23,
  },
  {
    name: 'The Conjuring',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '22.jpeg',
    image: '2.jpeg',
    category: 'Thriller',
    language: 'Chinese',
    year: '1999',
    time: '4hr',
    video: '',
    rate: 4.5,
    reviews: 18,
  },
  {
    name: 'Inception',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '3a.jpg',
    image: '3.jpg',
    category: 'Sci-Fi',
    language: 'English',
    year: '2010',
    time: '2hr 28min',
    video: '',
    rate: 4.8,
    reviews: 25,
  },
  {
    name: 'Parasite',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '4a.jpg',
    image: '4.jpg',
    category: 'Drama',
    language: 'Korean',
    year: '2019',
    time: '2hr 12min',
    video: '',
    rate: 4.6,
    reviews: 20,
  },
  {
    name: 'The Dark Knight',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '5a.jpg',
    image: '5.jpg',
    category: 'Action',
    language: 'English',
    year: '2008',
    time: '2hr 32min',
    video: '',
    rate: 4.9,
    reviews: 30,
  },
  {
    name: 'Interstellar',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '6a.jpg',
    image: '6.jpg',
    category: 'Sci-Fi',
    language: 'English',
    year: '2014',
    time: '2hr 49min',
    video: '',
    rate: 4.7,
    reviews: 22,
  },
  {
    name: 'Titanic',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '7a.jpg',
    image: '7.jpg',
    category: 'Romance',
    language: 'English',
    year: '1997',
    time: '3hr 15min',
    video: '',
    rate: 4.6,
    reviews: 28,
  },
  {
    name: 'Avengers: Endgame',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '8a.jpg',
    image: '8.jpg',
    category: 'Action',
    language: 'English',
    year: '2019',
    time: '3hr 1min',
    video: '',
    rate: 4.8,
    reviews: 35,
  },
  {
    name: 'Joker',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '9a.jpg',
    image: '9.jpg',
    category: 'Drama',
    language: 'English',
    year: '2019',
    time: '2hr 2min',
    video: '',
    rate: 4.5,
    reviews: 24,
  },
  {
    name: 'Toy Story 4',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    titleImage: '10a.jpg',
    image: '10.jpg',
    category: 'Animation',
    language: 'English',
    year: '2019',
    time: '1hr 40min',
    video: '',
    rate: 4.3,
    reviews: 15,
  }
];
