import React, { useState, useEffect } from 'react';
import './styles.css'

function CommentList({ articleId }) {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(`https://readnite.online/extras/submitComment.php/extras/getComments.php?articleId=${articleId}`);  // Adjust the endpoint URL as needed
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setComments(data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [articleId]);

  return (
    <div className="list dark-mode">
      <h2>Comments</h2>
      <ul>
        {comments.map(comment => (
          <div key={comment.id} className="comment-item">
            <div className="flex justify-between items-center">
              <p className="mr-4"><strong>{comment.user}:</strong></p>
              <p className="date text-xs text-gray-500">{new Date(comment.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
            </div>

            <p className="comment"> {comment.says}</p>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default CommentList;
