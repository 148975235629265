import React, { useEffect, useState } from 'react';
import ArticleCard from './ArticleCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const RelatedsFinance = ({ currentArticleId }) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch('/cache/articles_finance_cache.json');
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                const data = await response.json();

                // Filter out the current article to avoid showing it in related articles
                const filteredData = data.filter(article => article.articleId !== currentArticleId);

                // Randomize the articles
                const shuffledArticles = filteredData.sort(() => 0.5 - Math.random());

                setArticles(shuffledArticles);
            } catch (error) {
                console.error('Error fetching related articles:', error);
            }
        };

        fetchArticles();
    }, [currentArticleId]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1, // Display 1 group of 6 articles per slide
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    // Function to group articles into sets of 6 (2 rows of 3 articles each)
    const groupArticlesInRows = (articles, itemsPerGroup) => {
        const groupedArticles = [];
        for (let i = 0; i < articles.length; i += itemsPerGroup) {
            groupedArticles.push(articles.slice(i, i + itemsPerGroup));
        }
        return groupedArticles;
    };

    const groupedArticles = groupArticlesInRows(articles, 6); // 6 articles per group

    return (
        <div className="related-articles-container">
            <h2 className="related-articles-title text-2xl font-semibold mb-6">You May Like</h2>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
            >
                {groupedArticles.map((group, groupIndex) => (
                    <div key={groupIndex} className="p-4">
                        <div className="grid grid-cols-3 gap-4">
                            {group.map((article) => (
                                <div key={article.articleId} className="col-span-1">
                                    <ArticleCard article={article} />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default RelatedsFinance;
