import React, { useEffect, useState } from 'react';
import ArticleCard from './ArticleCard'; 
import Pickart from './Pickart'; // Import the Pickart component

const ForYouFinance = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        // Function to shuffle an array
        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        // Fetch the articles from the cache file
        const fetchArticles = async () => {
            try {
                const response = await fetch('/cache/articles_gaming_cache.json', {
                    mode: 'cors',
                });
                if (!response.ok) throw new Error('Failed to fetch articles');
                
                const data = await response.json();
                const shuffledArticles = shuffleArray(data); // Shuffle the articles
                setArticles(shuffledArticles.slice(0, 6)); // Get only 6 articles after shuffling
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    // Return nothing if no articles are available
    if (!articles || articles.length === 0) return null;

    return (
        <div className="container mx-auto border-t border-b border-gray-300 py-10 mb-20">
            <h1 className="text-2xl font-bold text-subMain mb-10">For You</h1>         
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {/* First Row: 3 Articles + 1 Pickart */}
                {articles.slice(0, 3).map((article, index) => (
                    <ArticleCard key={index} article={article} />
                ))}
                <Pickart key="pickart-1" /> {/* The Pickart for the first row */}
                
                {/* Second Row: 3 Articles + 1 Pickart */}
                {articles.slice(3, 6).map((article, index) => (
                    <ArticleCard key={index + 3} article={article} />
                ))}
                <Pickart key="pickart-2" /> {/* The Pickart for the second row */}
            </div>
        </div>
    );
};

export default ForYouFinance;
