import React, { useState } from 'react';

function CommentForm({ articleId, onCommentSubmit }) {
  const [user, setUser] = useState(''); // State for the user name
  const [says, setSays] = useState(''); // State for the comment

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!articleId) {
      console.error('articleId is undefined');
      return;
    }

    try {
      const response = await fetch('https://readnite.online/extras/submitComment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          articleId,
          user,
          says,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear the form fields after successful submission
      setUser('');
      setSays('');
      onCommentSubmit(); // Refresh the comment list
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="comment-form-container">
      <h2 className="form-title">Leave a Comment</h2>
      <div className="comment-form">
        <div className="input-container">
          <input
            id="name"
            type="text"
            placeholder=" "
            value={user}
            onChange={(e) => setUser(e.target.value)}
            required
          />
          <label htmlFor="name">Name*</label>
        </div>
        <div className="input-container">
          <textarea
            id="comment"
            placeholder=" "
            value={says}
            onChange={(e) => setSays(e.target.value)}
            required
          />
          <label htmlFor="comment">Comment*</label> 
        </div>
      </div>
      <button type="submit" className="submit-button">POST</button> 
    </form>
  );
}

export default CommentForm;
