import React from 'react';
import technology from '../images/technology.jpg';

const HeroTechnology = () => {
  return (
    <div 
      className="w-full p-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${technology})` }}
    >
      <div className="flex flex-col md:flex-row items-center justify-between h-full px-6">
        
        {/* Text Section */}
        <div className="md:w-1/2 text-center md:text-left p-6">
          <h1 className="text-5xl font-bold text-white mb-4">
            Technology & Innovation
          </h1>
          <p className="text-lg text-white mb-6">
            Stay ahead in a fast-changing world with our gathered tips on the latest technology. Discover how tech can make your life easier and more connected every day.
          </p>
          <button className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700">
            Learn More
          </button>
        </div>

        {/* Empty Section for Layout Spacing */}
        <div className="md:w-1/2"></div>
      </div>
    </div>
  );
};

export default HeroTechnology;
