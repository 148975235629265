import React, { useEffect, useState } from 'react';
import ArticleCard from './ArticleCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const RelatedsTechnology = ({ currentArticleId }) => {
    const [articles, setArticles] = useState([]);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

    // Monitor window resize events to check screen size
    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch('/cache/articles_technology_cache.json');
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                const data = await response.json();

                // Filter out the current article to avoid showing it in related articles
                const filteredData = data.filter(article => article.articleId !== currentArticleId);

                // Randomize the articles
                const shuffledArticles = filteredData.sort(() => 0.5 - Math.random());

                setArticles(shuffledArticles);
            } catch (error) {
                console.error('Error fetching related articles:', error);
            }
        };

        fetchArticles();
    }, [currentArticleId]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    // Group articles based on screen size
    const groupArticles = (articles, itemsPerGroup) => {
        const groupedArticles = [];
        for (let i = 0; i < articles.length; i += itemsPerGroup) {
            groupedArticles.push(articles.slice(i, i + itemsPerGroup));
        }
        return groupedArticles;
    };

    // Grouping logic: 4 articles for smaller screens, 6 for larger screens
    const groupedArticles = groupArticles(articles, isLargeScreen ? 6 : 4);

    return (
        <div className="related-articles-container enlarged">
            <h2 className="related-articles-title">You May Like</h2>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
            >
                {groupedArticles.map((group, index) => (
                    <div key={index} className="related-articles-slide">
                        <div className={`grid ${isLargeScreen ? 'grid-cols-3' : 'grid-cols-2'} gap-4`}>
                            {group.map((article, index) => (
                                <div key={index} className="related-article-card">
                                    <ArticleCard article={article} />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default RelatedsTechnology;
