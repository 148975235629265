import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ArticleCard = ({ article, innerRef }) => {
    const navigate = useNavigate();

    if (!article || !article.slug || !article.image || !article.link || !article.category || !article.articleId) {
        return null; 
    }

    const handleLinkClick = (event) => {
        event.preventDefault();
        navigate(`/${encodeURIComponent(article.slug)}`, { replace: true });
    };

    return (
        <div
            ref={innerRef}
            className="card relative overflow-hidden shadow-lg transition-all duration-300 transform hover:scale-105"
            style={{
                width: '100%',
                // marginBottom: '5px',  // Reduced margin for mobile
                backgroundColor: '#F4F4F4',  // Lightened background for contrast
                borderRadius: '8px',
            }}
        >
            <a href={`/${encodeURIComponent(article.slug)}`} onClick={handleLinkClick} className="group">
                <div 
                    className="relative"
                    style={{ height: '120px', overflow: 'hidden' }} // Reduced height for smaller devices
                >
                    <img
                        src={article.image}
                        alt={article.title}
                        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                </div>       
                <div 
                    className="text-black text-xs font-semibold mt-2 px-2 py-2 text-center transition-colors duration-300 group-hover:text-red-600"
                >
                    {article.title}
                </div>
            </a>
        </div>
    );
};

export default ArticleCard;
