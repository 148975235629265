// IndexedDB configuration
const dbName = 'ArticlesDB';
const dbVersion = 1;
const storeNames = [
    'topRatedStore',
    'popularMoviesStore',
    'financeArticlesStore',
    'bannerArticlesStore',
    'healthArticlesStore',
    'foodArticlesStore',
    'allMostArticlesStore',
    'allBannerArticlesStore',
    'relationshipsArticlesStore',
    'technologyArticlesStore',
    'allPopularArticlesStore',
    'gamingArticlesStore',
    'newsArticlesStore',
    'featuredArticlesStore'
];

let db;

// Open IndexedDB
const openDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, dbVersion);

        request.onupgradeneeded = (event) => {
            db = event.target.result;
            storeNames.forEach(storeName => {
                if (!db.objectStoreNames.contains(storeName)) {
                    // Create object store with keyPath 'id'
                    db.createObjectStore(storeName, { keyPath: 'id' });
                }
            });
        };

        request.onsuccess = (event) => {
            db = event.target.result;
            resolve(db);
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
};

// Save articles to IndexedDB
export const saveArticles = async (storeName, articles) => {
    try {
        const db = await openDB();
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);

        articles.forEach(article => {
            if (!article.id) {
                // If 'id' is missing, assign a unique identifier
                article.id = article.articleId || Date.now() + Math.random().toString(36).substr(2, 9);
            }
            store.put(article);  // Store or update the article
        });

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => resolve();
            transaction.onerror = (event) => reject(event.target.error);
        });
    } catch (error) {
        console.error('Error saving articles:', error);
    }
};

// Get all articles from a specific store
export const getArticles = async (storeName) => {
    try {
        const db = await openDB();
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);

        return new Promise((resolve, reject) => {
            const request = store.getAll();
            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.error);
        });
    } catch (error) {
        console.error('Error fetching articles:', error);
    }
};

// Get a specific article by 'articleId'
export const getArticleById = async (storeName, articleId) => {
    try {
        const db = await openDB();
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);

        return new Promise((resolve, reject) => {
            const request = store.get(articleId);  // Get specific article
            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.error);
        });
    } catch (error) {
        console.error('Error fetching article by ID:', error);
    }
};

// Clear all articles from a specific store
export const clearArticles = async (storeName) => {
    try {
        const db = await openDB();
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);

        return new Promise((resolve, reject) => {
            const request = store.clear();
            request.onsuccess = () => resolve();
            request.onerror = (event) => reject(event.target.error);
        });
    } catch (error) {
        console.error('Error clearing articles:', error);
    }
};
