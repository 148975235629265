import React, { useState } from 'react'
import Layout from '../Layout/Layout'
import Movie from "../Components/Movie";
import { Movies } from "../Data/MovieData"
import { CgSpinner } from "react-icons/cg"

function MoviesPage() {
	return (
		<Layout>
		<div className="min-height-screen container mx-auto px-2 my-6">
			<p className="text-lg font-medium my-6">
				Total <span className="font-bold text-subMain">{Movies?.length}</span>
			</p>
			<div className="grid sm:mt-10 mt-6 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-">
				I dont know what this was meant to mean
			</div>
			{/* Loading More */}
			<div className="w-full flex flex-colo md:my-20 my-10">
				<button className="flex-rows gap-3 text-white py-3 px-8 rounded font-semibold border-2 border-SubMain">
					Loading More <CgSpinner className="animate-spin" />
				</button>
			</div>
		</div>
		</Layout>
	);
}

export default MoviesPage;