import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUs from './Screens/AboutUs';
import HomeScreen from './Screens/HomeScreen';
import ContactUs from './Screens/ContactUs';
import MoviesPage from './Screens/Movies';
import Singlemovie from './Screens/Singlemovie';

import WatchPage from "./Screens/WatchPage";
import MoviesList from "./Screens/Dashboard/Admin/MovieList";
import LinkFactory from "./LinkFactory";

import AllBanner from "./AllBanner"
import AllPopular from "./AllPopular"
import AllMost from "./AllMost"
import Technology from "./Technology"
import Finance from "./Finance"
import Health from "./Health"
import Food from "./Food"
import Gaming from "./Gaming"
import Relationships from "./Relationships"
import News from "./News"
import Featured from './Components/Home/Featured';
import ArticleLoader from './extras/ArticleLoader';

import AOS from 'aos';
import './index.css'

function App() {
  AOS.init();
  return (
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/movies" element={<MoviesPage />} />
        <Route path="/movie/:id" element={<Singlemovie />} />
        <Route path="/watch/:id" element={<WatchPage />} />
        <Route path="/movieslist" element={<MoviesList />} />
        <Route path="/linkfactory" element={<LinkFactory />} />
        <Route path="/allbanner" element={<AllBanner />} />
        <Route path="/trending" element={<AllPopular />} />
        <Route path="/hot" element={<AllMost />} />
        <Route path="/featured" element={<Featured />} />

        <Route path="/news" element={<News />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/health" element={<Health />} />
        <Route path="/food" element={<Food />} />
        <Route path="/gaming" element={<Gaming />} />
        <Route path="/relationships" element={<Relationships />} />
        <Route path="/article" element={<ArticleLoader />} />  {/* Original query parameter link */}
        <Route path="/:slug" element={<ArticleLoader />} />     {/* Slug-based link */}
      </Routes>
  );
}

export default App;
