import React, { useEffect, useState, useRef, useCallback } from 'react';
import Layout from './Layout/Layout';
import { saveArticles, getArticles } from './indexedDBHelper';
import ArticleCardMiddle from './articleCardsNews/ArticleCardMiddle'; // Only ArticleCardMiddle
import ArticleCardNarrow from './articleCardsNews/ArticleCardNarrow'; // Narrow card for the left side
import Headlines from './articleCardsNews/Headlines'; // Import the Headlines component
import { useNavigate } from 'react-router-dom';
import ForYouNews from './foryou/ForYouNews';
import HeroNews from './heroBanners/HeroNews';

const News = () => {
    const [articles, setArticles] = useState([]);
    const [visibleArticles, setVisibleArticles] = useState(10);
    const observer = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const storedArticles = await getArticles('newsArticlesStore');
                if (storedArticles.length > 0) {
                    setArticles(storedArticles);
                } else {
                    const response = await fetch('/cache/articles_finance_cache.json', {
                        mode: 'cors',
                    });
                    if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);

                    const data = await response.json();
                    setArticles(data);
                    await saveArticles('newsArticlesStore', data);
                }
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };
        fetchArticles();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on mount
    }, []);

    const loadMoreArticles = () => {
        setVisibleArticles((prev) => prev + 10);
    };

    const lastArticleElementRef = useCallback((node) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreArticles();
            }
        });
        if (node) observer.current.observe(node);
    }, []);

    const handleArticleClick = async (slug) => {
        try {
            await fetch('http://localhost/news/src/extras/update_click_count.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ slug }),
            });
        } catch (error) {
            console.error('Error updating click count:', error);
        }
    };

    return (
        <Layout>
            {/* Hero Section */}
            <HeroNews />

            <div className="min-h-screen bg-gray-100 p-4">
                {/* Main Title */}
                <div className="text-center">
                    <h1 className="text-6xl font-bold font-lora text-center inline-flex relative">
                        <span className="text-red-600">DAILY</span>
                        <span className="text-black">NEWS</span>
                        <span className="absolute w-full border-b-4 border-red-600 border-opacity-50 bottom-0"></span>
                        <span className="absolute w-full border-b-2 border-red-600 bottom-[-2px]"></span>
                    </h1>
                </div>

                {/* Layout with narrow left side and main content */}
                <div className="flex flex-col md:flex-row mt-8">
                    {/* Narrow Left Side */}
                    <div className="w-full md:w-1/5 border-r-2 border-gray-300 pr-2">
                        {/* Render narrow articles here */}
                        {articles.slice(0, 5).map((article) => (
                            <ArticleCardNarrow key={article.slug} article={article} />
                        ))}
                    </div>

                    {/* Main News Content */}
                    <div className="w-full md:w-3/4 pl-4">
                        {/* For You Section */}
                        <ForYouNews />

                        {/* Happening Now Section */}
                        <h1 className="text-2xl text-subMain font-bold mb-8">Happening Now</h1>

                        {/* ArticleCardMiddle and Headlines Sections */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {/* Left 2 columns: ArticleCardMiddle */}
                            <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
                                {articles.slice(5, visibleArticles).map((article, index) => (
                                    <div
                                        key={article.slug}
                                        onClick={() => {
                                            navigate(`/${article.slug}`, { state: { article } });
                                            handleArticleClick(article.slug);
                                        }}
                                    >
                                        <ArticleCardMiddle
                                            article={article}
                                            innerRef={index === visibleArticles - 1 ? lastArticleElementRef : null}
                                        />
                                    </div>
                                ))}
                            </div>

                            {/* Right 1 column: Headlines */}
                            <div className="col-span-1 border-l-2 border-gray-300 pl-4">
                                <Headlines /> {/* Headlines component with vertical separator */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default News;
