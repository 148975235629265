import React from 'react';
import finance from '../images/finance.jpg';

const HeroFinance = () => {
  return (
    <div 
      className="w-full p-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${finance})` }}
    >
      <div className="flex flex-col md:flex-row items-center justify-between h-full px-6">
        
        {/* Text Section */}
        <div className="md:w-1/2 text-center md:text-left p-6">
          <h1 className="text-5xl font-bold text-gray-800 mb-4">
            Finance & Investing
          </h1>
          <p className="text-lg text-gray-800 mb-6">
            Take control of your money with simple, easy-to-follow tips for smart investing and saving. Learn how to grow your wealth and manage your finances with confidence.
          </p>
          <button className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700">
            Learn More
          </button>
        </div>

        {/* Empty Section for Layout Spacing */}
        <div className="md:w-1/2"></div>
      </div>
    </div>
  );
};

export default HeroFinance;



