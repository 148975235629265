import React from 'react';
import { Link } from 'react-router-dom';

const ArticleCardNarrow = ({ article, innerRef }) => {
    if (!article || !article.slug || !article.image || !article.link || !article.category || !article.articleId) {
        return null;
    }

    return (
        <Link to={`/${encodeURIComponent(article.slug)}`} state={{ article }}>
            <div ref={innerRef} className="p-4 flex flex-col group">
                <h3 className="text-sm font-bold text-gray-800 mb-2">
                {article.title}
                </h3>

            {/* Article Image */}
            <div className="w-full h-40 bg-gray-200 overflow-hidden mb-2">
                <img 
                    src={article.image} 
                    alt={article.title} 
                    className="w-full h-full object-cover"
                />
            </div>

            {/* Article Brief */}
            <p className="text-xs text-justify text-gray-600">
                {article.brief}
            </p>
            </div>
        </Link>
    );
};

export default ArticleCardNarrow;
