import React from 'react';
import { Link } from 'react-router-dom';

const ArticleCard = ({ article, innerRef }) => {
    // Ensure that essential fields are present
    if (!article || !article.slug || !article.image || !article.link || !article.category || !article.articleId) {
        return null; // Don't render the card if essential data is missing
    }

    return (
        <Link
            to={`/${encodeURIComponent(article.slug)}`} // Use slug as URL
            state={{ article }} // Pass the full article object including category and articleId
        >
            <div
                ref={innerRef}
                className="overflow-hidden group transition-all hover:scale-105 duration-300"
                style={{ maxWidth: '350px', height: '400px', position: 'relative' }} // Adjust height and width
            >
                {/* Image Section */}
                <div className="relative w-full h-1/2">
                    <img
                        src={article.image}
                        alt={article.title}
                        className="w-full h-full object-cover"
                    />
                </div>

                {/* Text Section */}
                <div className="pt-4 p-1 h-1/3 flex flex-col">
                    <div>
                        <h2 className="text-sm font-semibold text-gray-800 group-hover:text-blue-600 transition-colors duration-300">
                            {article.title}
                        </h2>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ArticleCard;
