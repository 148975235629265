import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Pickart = ({ article, innerRef }) => {    
    const [articles, setArticles] = useState([]);

    // Function to shuffle and pick a random article from an array
    const getRandomArticle = (array) => {
        const shuffledArray = array.sort(() => 0.5 - Math.random());
        return shuffledArray[0];
    };

    useEffect(() => {
        const fetchArticles = async () => {
            const sources = [
                '/cache/articles_relationships_cache.json',
                '/cache/articles_finance_cache.json',
                '/cache/articles_food_cache.json',
                '/cache/articles_technology_cache.json'
            ];

            try {
                const fetchPromises = sources.map((source) => fetch(source).then(res => res.json()));
                const results = await Promise.all(fetchPromises);

                const randomArticles = results.map((articles) => getRandomArticle(articles));
                setArticles(randomArticles);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <div className="grid grid-cols-2 gap-x-4 gap-y-8"> {/* Reduced vertical gap (gap-y-2) */}
            {articles.map((article, index) => (
                <Link
                    to={`/${encodeURIComponent(article.slug)}`}  // Use slug as URL
                    state={{ article }}  // Pass the full article object including category and articleId
                >
                    <div
                        key={index}
                        className="relative overflow-hidden shadow-lg transform transition-transform duration-300 hover:scale-105"
                        style={{ height: '150px' }}
                    >
                        <img
                            src={article.image} // assuming the article object contains an 'image' field
                            alt={article.title}
                            className="w-full h-full object-cover absolute inset-0 z-0"
                        />
                        <div className="absolute bottom-0 left-0 w-full text-white text-center p-2 z-10">
                            <h2 className="text-xs font-bold">{article.title}</h2>
                        </div>
                    </div>
                 </Link>
            ))}
        </div>
    );
};

export default Pickart;
