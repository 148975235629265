import React from 'react';
import most from '../images/most.jpg';

const HeroMost = () => {
  return (
    <div 
      className="w-full p-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${most})` }}
    >
      <div className="flex flex-col md:flex-row items-center justify-between h-full px-6">
        
        {/* Text Section */}
        <div className="md:w-1/2 text-center md:text-left p-6">
          <h1 className="text-5xl font-bold text-white mb-4">
            Most Read Pages
          </h1>
          <p className="text-lg text-white mb-6">
            Check out our most popular pages for the latest insights and tips. See what others are reading and stay informed on the topics that matter most.
          </p>
          <button className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700">
            Learn More
          </button>
        </div>

        {/* Empty Section for Layout Spacing */}
        <div className="md:w-1/2"></div>
      </div>
    </div>
  );
};

export default HeroMost;
