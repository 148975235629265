import React, { useEffect, useState, useRef, useCallback } from 'react';
import { saveArticles, getArticles } from '../../indexedDBHelper'; // Import IndexedDB helper functions

// Reusable ArticleCard Component
const ArticleCard = ({ article, innerRef }) => (
    <div ref={innerRef} className="bg-white rounded-lg p-2 shadow-lg flex" style={{ height: '250px', position: 'relative' }}>
        <div className="relative" style={{ width: '50%' }}>
            <img
                src={article.image}
                alt={article.title}
                className="w-full h-full object-cover rounded"
            />
        </div>
        <div className="ml-4 flex flex-col justify-between relative" style={{ width: '50%' }}>
            <div style={{ height: 'calc(100% - 52px)', overflow: 'hidden', position: 'relative' }}>
                <h2 className="text-sm sm:text-lg font-bold mb-1 text-bgLight">{article.title}</h2>
                <p className="text-sm sm:text-lg xl:text-sm text-gray-700 mb-2" style={{ paddingBottom: '30px' }}>
                    {article.brief}...
                </p>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    height: '50px',
                    background: 'linear-gradient(to top, white, rgba(255, 255, 255, 0))'
                }}></div>
            </div>
            <a
                href={article.link} // Direct link to the local HTML file
                className="bg-black/20 hover:bg-blue-700 active:bg-blue-900 text-white font-bold py-2 px-4 rounded absolute bottom-0"
                style={{ width: '150px' }}
            >
                Read More
            </a>
        </div>
    </div>
);

const Featured = () => {
    const [articles, setArticles] = useState([]);
    const [visibleArticles, setVisibleArticles] = useState(10); // Number of articles to display initially
    const observer = useRef();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                // Check if articles are already stored in IndexedDB
                const storedArticles = await getArticles('featuredArticlesStore'); // Use appropriate store name
                if (storedArticles.length) {
                    setArticles(storedArticles);
                } else {
                    // Fetch the articles from the API if not found in IndexedDB
                    const response = await fetch('http://localhost/news/src/serverFeatured.php/api/articles');
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    const data = await response.json();
                    setArticles(data);
                    await saveArticles('featuredArticlesStore', data); // Save to IndexedDB
                }
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    const loadMoreArticles = () => {
        setVisibleArticles(prevVisibleArticles => prevVisibleArticles + 10); // Load 10 more articles
    };

    const lastArticleElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMoreArticles();
            }
        });
        if (node) observer.current.observe(node);
    }, []);

    return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-dry border-t-2 border-black p-4">
                <h1 className="text-2xl text-subMain text-left mb-8">Featured Articles</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {articles.slice(0, visibleArticles).map((article, index) => (
                        <ArticleCard
                            key={article.id} // Use article.id for unique key
                            article={article}
                            innerRef={index === visibleArticles - 1 ? lastArticleElementRef : null}
                        />
                    ))}
                </div>
            </div>
    );
};

export default Featured;
