import React, { useState } from 'react';
import axios from 'axios';

function LinkFactory() {
    const [links, setLinks] = useState({
        banner: '',
        popular: '',
        most: '',
        technology: '',
        finance: '',
        health: '',
        food: '',
        gaming: '',
        relationships: '',
    });
    const [messages, setMessages] = useState({});
    const [githubMessages, setGithubMessages] = useState({});

    const handleSubmit = async (e, type) => {
        e.preventDefault();
        let endpoint = '';
        switch (type) {
            case 'banner':
                endpoint = 'linkFactory.php?type=banner';
                break;
            case 'popular':
                endpoint = 'linkFactory.php?type=popular';
                break;
            case 'most':
                endpoint = 'linkFactory.php?type=most';
                break;
            case 'technology':
                endpoint = 'linkFactory.php?type=technology';
                break;
            case 'finance':
                endpoint = 'linkFactory.php?type=finance';
                break;
            case 'health':
                endpoint = 'linkFactory.php?type=health';
                break;
            case 'food':
                endpoint = 'linkFactory.php?type=food';
                break;
            case 'gaming':
                endpoint = 'linkFactory.php?type=gaming';
                break;
            case 'relationships':
                endpoint = 'linkFactory.php?type=relationships';
                break;
            case 'news':
                endpoint = 'linkFactory.php?type=news';
                break;
            default:
                break;
        }
        try {
            const response = await axios.post(`http://localhost/news/src/${endpoint}`, { link: links[type] });
            setMessages(prev => ({ ...prev, [type]: response.data.saveMessage }));
            setGithubMessages(prev => ({ ...prev, [type]: response.data.githubMessage }));
            setLinks(prev => ({ ...prev, [type]: '' }));
        } catch (error) {
            setMessages(prev => ({ ...prev, [type]: 'Error saving link' }));
            setGithubMessages(prev => ({ ...prev, [type]: 'Error uploading link to GitHub' }));
        }
    };

    const handleChange = (e, type) => {
        setLinks(prev => ({ ...prev, [type]: e.target.value }));
    };

    const renderFormSection = (type, placeholder) => (
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg text-white w-2/3 flex flex-col items-center">
            <div className="w-full flex justify-between items-center mb-4">
                <div className="w-3/4">
                    <h2 className="text-2xl mb-4">Links {type.charAt(0).toUpperCase() + type.slice(1)}</h2>
                    <textarea
                        placeholder={`Enter your ${type} link`}
                        value={links[type]}
                        onChange={(e) => handleChange(e, type)}
                        className="w-full p-2 px-8 mb-4 text-white bg-slate-900 rounded"
                    />
                </div>
                <button onClick={(e) => handleSubmit(e, type)} className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Save Links
                </button>
            </div>
            {messages[type] && <p className="mt-4">{messages[type]}</p>}
            {githubMessages[type] && <p className="mt-4">{githubMessages[type]}</p>}
        </div>
    );

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 space-y-2">
            {renderFormSection('news', 'Enter your news links')}
            {renderFormSection('banner', 'Enter your banner links')}
            {renderFormSection('popular', 'Enter your popular links')}
            {renderFormSection('most', 'Enter your most links')}
            {renderFormSection('technology', 'Enter your technology links')}
            {renderFormSection('finance', 'Enter your finance links')}
            {renderFormSection('health', 'Enter your health links')}
            {renderFormSection('food', 'Enter your food links')}
            {renderFormSection('gaming', 'Enter your gaming link')}
            {renderFormSection('relationships', 'Enter your relationships links')}
        </div>
    );
}

export default LinkFactory;
