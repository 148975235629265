import React from 'react';
import Layout from '../Layout/Layout'
import PopularMovies from '../Components/Home/PopularMovies';
import Promos from '../Components/Home/Promos';
import Banner from '../Components/Home/Banner';
import TopRated from '../Components/Home/TopRated';
import Featured from '../Components/Home/Featured';

function HomeScreen() {
	return (
		<Layout>
			<div className="container mx-auto min-h-screen px-2 mb-6">
				<Banner />
				<PopularMovies />
				<Promos />
				<TopRated />
				<Featured />
			</div>
		</Layout>
	);
}

export default HomeScreen;