import React from 'react';
import jogg from '../images/jogg.jpg';

const HeroHealth = () => {
  return (
    <div 
      className="w-full p-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${jogg})` }}
    >
      <div className="flex flex-col md:flex-row items-center justify-between h-full px-6">
        
        {/* Text Section */}
        <div className="md:w-1/2 text-center md:text-left p-6">
          <h1 className="text-5xl font-bold text-gray-800 mb-4">
            Health & Fitness
          </h1>
          <p className="text-lg text-gray-600 mb-6">
            Feel your best every day with simple, practical tips for a healthier, happier life. Start making small changes that lead to big improvements in your well-being.
          </p>
          <button className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700">
            Learn More
          </button>
        </div>

        {/* Empty Section for Layout Spacing */}
        <div className="md:w-1/2"></div>
      </div>
    </div>
  );
};

export default HeroHealth;
