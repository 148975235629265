import React from 'react'
import { FiMail, FiPhoneCall, FiMapPin } from 'react-icons/fi'
import Head from '../Components/Head';
import Layout from '../Layout/Layout';

// <div className="grid grid-flow-row xl:grid-cols-2 gap-4 xl:gap-16 items-center">
// 					<div>
// 						<h3 className="text-xl lg:text-3xl mb font-semi-bold">
// 							Welcome to our MovieXone
// 						</h3>
// 						<div className="mt-3 text-sm leading-8 text-text">
// 							<p>

// 							</p>
// 						</div>
// 					</div>
// 				</div>

function ContactUs() {
	const ContactData = [
	{
		id:1,
		title:"Email Us",
		info:"Interactively grow backend ideasfor cross-platform models.",
		icon:FiMail,
		contact:'readnight@gmail.com',
	},
	{
		id:2,
		title:"Call Us",
		info:"Distinctively exploit optimal alignments for intuitive bandwidth.",
		icon:FiPhoneCall,
		contact:'+256 772 169 960',
	},
	{
		id:3,
		title:"Location",
		info:"Dar es Salaam, Tanzania. 356 Kigoboni, Street No.2.",
		icon:FiMapPin,
		contact:'+256 750 148 824',
	}

		]
	return(
		<Layout>
		<div className="min-height-screen container mx-auto px-2 my-6">
			<Head title="Contact Us" />
			<div className="grid mg:grid-cols-2 gap-6 lg:my-20 my-10 lg:grid-cols-3 xl:">
				{
					ContactData.map((item) => (
						<div key={item.id} className="border-border flex-colo p-10 bg-dry rounded-lg text text-center">
							<span className="flex-colo w-20 h-20 rounded-full bg-main text-subMain text-2xl">
								<item.icon />
							</span>
							<h5 className="text-xl font-semibold mb-2">{item.title}</h5>
							<p className='mb-0 text-sm text-text leading-7'>
								<a href={'mailto{item.contact}'} className="text-blue-600">{item.contact}</a>{' '}
								{item.info}
							</p>
						</div>
					))
				}
			</div>
		</div>
		</Layout>
	);
}

export default ContactUs;